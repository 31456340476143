import { render, staticRenderFns } from "./dangerItem.vue?vue&type=template&id=be75a5ce"
import script from "./dangerItem.vue?vue&type=script&lang=js"
export * from "./dangerItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\강이화\\구영테크\\GuyoungPartner\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be75a5ce')) {
      api.createRecord('be75a5ce', component.options)
    } else {
      api.reload('be75a5ce', component.options)
    }
    module.hot.accept("./dangerItem.vue?vue&type=template&id=be75a5ce", function () {
      api.rerender('be75a5ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/chm/base/dangerItem.vue"
export default component.exports